import { useState, useRef, useEffect } from "react";
import "./ProfileSettings.scss";
import { useNavigate } from "react-router-dom";
import i18n from "../../../i18nConfig";
import { useTranslation } from "react-i18next";
import ProfileIcon from "../../../utils/Images/ProfileIcon";
import AccountIcon from "../../../utils/Images/AccountIcon";
import NotificationIcon from "../../../utils/Images/NotificationIcon";
import SignoutIcon from "../../../utils/Images/SignoutIcon";
import SettingsIcon from "../../../utils/Images/SettingsIcon";
import ReferEarnIcon from "../../../utils/Images/ReferEarnIcon";
interface ProfileSettingsProps {
  email: any;
  profileData: any;
}
const ProfileSettings = ({ email, profileData }: ProfileSettingsProps) => {
  const [isPopoverVisible, setPopoverVisible] = useState(false);
  const popoverRef: any = useRef(null);
  const iconRef: any = useRef(null);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const industryId = localStorage.getItem("industryId");
  const currentLanguage = i18n.language;
  const togglePopover = () => {
    setPopoverVisible((prevVisible) => !prevVisible);
  };
  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (
        popoverRef.current &&
        !popoverRef.current.contains(event.target) &&
        iconRef.current &&
        !iconRef.current.contains(event.target)
      ) {
        setPopoverVisible(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  //function to logout
  const handleLogout = () => {
    localStorage.removeItem("companyToken");
    localStorage.removeItem("companyId");
    localStorage.removeItem("industryId");
    localStorage.removeItem("tempUniqueId");
    localStorage.removeItem("mediaPath");
    localStorage.removeItem("companyUserId");
    localStorage.removeItem("email");
    localStorage.removeItem("companyAddress");
    sessionStorage.clear();
    navigate("/");
  };

  //function to navigate on profile screen icon
  const handleProfileTabClick = () => {
    industryId === "null"
      ? navigate(`/${currentLanguage}/assessment/create-profile`, {
          state: { editProfile: false },
        })
      : (setPopoverVisible(false),
        navigate(`/${currentLanguage}/assessment/create-profile`, {
          state: { editProfile: true },
        }));
  };
  return (
    <div className="profile-settings">
      <div ref={iconRef} onClick={togglePopover}>
        {profileData?.logo ? (
          <div>
            <img src={profileData?.logo} alt="Logo" className="company-logo" />
          </div>
        ) : (
          <div className="logo-place">
            {email?.charAt(0).toLocaleUpperCase()}
          </div>
        )}
      </div>
      {isPopoverVisible && (
        <div className="popover" ref={popoverRef}>
          <div className="popover-header">
            <div className="logo-name-sec">
              {profileData?.logo ? (
                <img
                  src={profileData?.logo}
                  alt="Logo"
                  className="company-logo"
                />
              ) : (
                <div className="profile-icon company-logo">
                  {email?.charAt(0).toLocaleUpperCase()}
                </div>
              )}
              <div className="company-name">
                <strong>
                  {profileData?.companyName ? profileData?.companyName : "-"}
                </strong>
                <div className="email-date-section">
                  <span>{profileData?.email ?? email}</span>
                </div>
              </div>
            </div>
          </div>
          <div className="popover-body">
            <div
              className="tab-item popover-item "
              onClick={handleProfileTabClick}
            >
              <ProfileIcon />
              <div>{t("GENERAL.PROFILE")}</div>
            </div>
            <div className="tab-item popover-item">
              <NotificationIcon />
              <div>{t("GENERAL.NOTIFICATION")}</div>
            </div>
            <div className="tab-item popover-item">
              <ReferEarnIcon />
              <div>{t("GENERAL.REFER_EARN")}</div>
            </div>
            <div className="tab-item popover-item">
              <AccountIcon />
              <div>{t("GENERAL.ACCOUNT")}</div>
            </div>
            <div className="tab-item popover-item">
              <SettingsIcon />
              <div>{t("GENERAL.SETTINGS")}</div>
            </div>
            <hr />
            <div className="tab-item popover-item" onClick={handleLogout}>
              <SignoutIcon />
              <div>{t("GENERAL.LOGOUT")}</div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProfileSettings;
