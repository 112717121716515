import { useTranslation } from "react-i18next";
import "./Footer.scss";
import packageJson from "../../../package.json";
import LogoTagline from "../../utils/Images/LogoTagline";
interface props {
  onOpenPopup?: any;
}
const Footer = ({ onOpenPopup }: props) => {
  const { t } = useTranslation();
  return (
    <div className="footer">
      <div className="logo">
        <LogoTagline />
      </div>
      <div className="middle">
        <p>
          © 2024, Evluate Global, LLC. &nbsp;| &nbsp;{t("GENERAL.VERSION")}:
          {packageJson.version}
        </p>
      </div>
      <div className="base">
        <button className="getSupport" onClick={() => onOpenPopup("support")}>
          {" "}
          {t("GET_SUPPORT")}
        </button>
        <button className="reportBug" onClick={() => onOpenPopup("report")}>
          {" "}
          {t("REPORT_BUG")}
        </button>
      </div>
    </div>
  );
};

export default Footer;
