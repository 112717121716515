const ReferEarnIcon = () => {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.51596 3.06829C5.02046 1.83578 6.03228 1.27507 7.23803 1.1899C9.07128 1.07808 10.4141 2.91578 10.4141 4.54911C10.4141 5.00413 10.2442 5.4532 10.0893 5.76921C10.0076 5.93576 9.90823 6.09529 9.80376 6.23898C8.93399 7.43621 7.39844 7.80857 6.02399 7.18448C5.67707 7.02675 5.29115 6.74945 5.05346 6.44767C4.94952 6.31569 4.9467 6.34936 4.79636 6.12447C4.16763 5.18383 4.09105 4.10634 4.51596 3.06829ZM11.4445 8.84517C11.4832 8.90044 12.4214 9.86125 12.6852 10.1697C11.4258 10.1697 10.0145 10.1486 9.12777 11.3406C8.20718 12.5782 8.15231 13.4882 8.15231 15C8.53487 15 8.91743 15 9.3 15C9.3 13.7985 9.20101 12.6961 10.2626 11.8839C11.0815 11.2573 11.7773 11.3831 12.7496 11.3478C12.3169 11.7944 11.8843 12.2407 11.4516 12.6873C11.48 12.7959 11.5582 12.8403 11.6309 12.9142C11.8412 13.1239 12.0517 13.3337 12.2621 13.5435C13.1585 12.6239 14.0551 11.7042 14.9517 10.7846C14.9517 10.7797 14.9517 10.7749 14.9517 10.77C14.0503 9.85512 13.1491 8.94041 12.2478 8.02553C11.9801 8.29868 11.7124 8.57201 11.4445 8.84517ZM9.7203 0.748737C10.7046 1.4038 11.3503 2.52486 11.5417 3.69111C11.697 4.63679 11.4466 5.85202 11.0166 6.5105C9.92093 8.18813 9.01604 8.51566 7.08769 8.67825C5.9384 8.7753 4.94564 9.04233 4.01605 9.67182C2.59449 10.635 1.61479 12.0299 1.27457 13.7536C1.20487 14.1067 1.14752 14.6013 1.14752 15C0.764953 15 0.382562 15 0 15C0 12.7148 0.896947 10.967 2.18757 9.65021C3.07199 8.74757 3.81947 8.36728 4.90576 7.91568C4.78153 7.80837 4.65713 7.70105 4.53272 7.59374C3.95287 7.138 3.41644 6.18746 3.23574 5.4629C3.05011 4.71852 3.03458 3.80292 3.28356 3.04018C3.77747 1.52695 4.91794 0.455587 6.47714 0.0925831C7.57684 -0.163643 8.79106 0.130229 9.7203 0.748737Z"
        fill="#5A5A5A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.65 2.29591C13.7991 2.29591 13.92 2.41774 13.92 2.56802V2.68293C14.5069 2.78885 15 3.23327 15 3.83786C15 3.98814 14.8791 4.10997 14.73 4.10997C14.5809 4.10997 14.46 3.98814 14.46 3.83786C14.46 3.59171 14.2569 3.33115 13.92 3.23831V4.49698C14.5069 4.60292 15 5.04733 15 5.65191C15 6.2565 14.5069 6.70091 13.92 6.80685V6.92175C13.92 7.07203 13.7991 7.19386 13.65 7.19386C13.5009 7.19386 13.38 7.07203 13.38 6.92175V6.80685C12.793 6.70091 12.3 6.2565 12.3 5.65191C12.3 5.50164 12.4209 5.37981 12.57 5.37981C12.7191 5.37981 12.84 5.50164 12.84 5.65191C12.84 5.89808 13.0431 6.15862 13.38 6.25146V4.99279C12.793 4.88685 12.3 4.44245 12.3 3.83786C12.3 3.23327 12.793 2.78885 13.38 2.68293V2.56802C13.38 2.41774 13.5009 2.29591 13.65 2.29591ZM13.38 3.23831C13.0431 3.33115 12.84 3.59171 12.84 3.83786C12.84 4.08403 13.0431 4.34456 13.38 4.4374V3.23831ZM14.46 5.65191C14.46 5.40575 14.2569 5.14521 13.92 5.05237V6.25146C14.2569 6.15862 14.46 5.89808 14.46 5.65191Z"
        fill="#5A5A5A"
      />
    </svg>
  );
};

export default ReferEarnIcon;
