import { AssessmentButton, TypeSelect } from "ui-components";
import "./SkillCard.scss";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import SmartAssessLogo from "../../utils/Images/SmartAssessLogo";
interface SkillCardProps {
  skillItem: any;
  handleAddSkill: (skill: any) => void;
  isAdded?: boolean;
  textBtn: string;
}
const SkillCard: React.FC<SkillCardProps> = ({
  skillItem,
  handleAddSkill,
  isAdded,
  textBtn,
}) => {
  const { t } = useTranslation();
  const [selectedDifficultyLevels, setSelectedDifficultyLevels] = useState<{
    [key: string]: number;
  }>({});
  useEffect(() => {
    // Initialize the selectedDifficultyLevels with the values from skillItem if it exists
    if (Object.keys(skillItem?.selectedDifficultyLevels ?? {}).length) {
      setSelectedDifficultyLevels(skillItem.selectedDifficultyLevels);
    } else if (Object.keys(skillItem?.selectDifficultyLevel ?? {}).length) {
      setSelectedDifficultyLevels(skillItem.selectDifficultyLevel);
    } else {
      setSelectedDifficultyLevels({});
    }
  }, [skillItem]);

  const handleAddClick = () => {
    handleAddSkill({
      ...skillItem,
      selectedDifficultyLevels,
    });
  };

  const handleTypeSelectChange = (level: string, value: number) => {
    setSelectedDifficultyLevels((prev: any) => ({
      ...prev,
      [level]: value,
    }));
  };
  return (
    <div className="skillCard mb-3">
      <div className="skillHeading">
        <div className="skillName">
          <div className="skillFirstLetter">
            <img src={skillItem?.icon ?? skillItem?.skillIcon} alt="" />
          </div>
          <div className="skillEllip">
            <span title={skillItem?.skillName}>{skillItem?.skillName}</span>
          </div>
        </div>
        <span>{skillItem?.questionType}</span>
        {skillItem?.questionType === "Video" ? <SmartAssessLogo /> : null}
      </div>
      <div className="levels mt-4 mb-3">
        <span>{t("CREATE_ASSESSMENT.DIFFICULTY_LEVELS")}</span>
        <span>{t("CREATE_ASSESSMENT.NO_OF_QUESTIONS")}</span>
      </div>
      <div className="difficultyLevel">
        {(skillItem?.difficultyLevels ?? skillItem?.difficultyLevel)
          ?.split(",")
          ?.map((ele: string, ind: number) => {
            return (
              <div
                className="skillCardSelect d-flex justify-content-between mb-3"
                key={ind}
              >
                <span>{ele} </span>
                <TypeSelect
                  className={`${isAdded ? "customSelectTypeDisabled" : ""}`}
                  options={Array.from(
                    {
                      length:
                        skillItem?.totalQuestions + 1 ||
                        skillItem?.questionsCount + 1,
                    },
                    (v, k) => ({
                      label: String(k).padStart(2),
                      value: k,
                    }),
                  )}
                  loadingText=" "
                  value={
                    selectedDifficultyLevels[ele] ?? skillItem?.questionCount
                  }
                  onChange={(value: any) => handleTypeSelectChange(ele, value)}
                  placeholder="0"
                />
              </div>
            );
          })}

        <div className="addBtn">
          <AssessmentButton
            disabled={isAdded}
            className={isAdded ? "" : "lightBtn"}
            onClick={handleAddClick}
          >
            {isAdded ? "Added" : textBtn}
          </AssessmentButton>
        </div>
      </div>
    </div>
  );
};

export default SkillCard;
