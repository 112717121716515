import {
  AttachmentIcon,
  CheckboxRadio,
  InputField,
  AssessmentButton,
  DeleteIcon,
} from "ui-components";
import "./AnswerOptions.scss";
import { useTranslation } from "react-i18next";
import { ImageUploader } from "../../../../../utils/Common/MediaUploader";
import {
  calculateMinusAmount,
  checkShouldAddInputValue,
  generateUUID,
  isAnswerSetValid,
} from "../../../../../utils/Common/Constant";

export interface AnswerOptionsProps {
  answerOptionState: any;
  answerOptionSetState: any;
  addBtnCallBack: (callBackData: any) => any;
  selectedType: any;
  setSelectedType: any;
  answerError: string | null;
  setAnswerError: any;
  isQuestionAnsEdited: boolean;
  setIsQuestionAnsEdited: any;
}
const AnswerOptions: React.FunctionComponent<AnswerOptionsProps> = ({
  answerOptionState,
  answerOptionSetState,
  addBtnCallBack,
  selectedType,
  setSelectedType,
  answerError,
  setAnswerError,
  isQuestionAnsEdited,
  setIsQuestionAnsEdited,
}) => {
  const { t } = useTranslation();
  const textAnsLimit = 1000;
  const companyId = localStorage.getItem("companyId") ?? "";
  const handleInputChange = (id: any, value: any) => {
    answerOptionSetState((prevState: any) => {
      const updateData = {
        ...prevState,
        [selectedType.type]: id
          ? prevState[selectedType.type].map((choice: any) =>
              choice.id === id ? { ...choice, label: value } : choice,
            )
          : value,
      };
      constErrorCheckingAnswer(updateData);
      return updateData;
    });
  };

  // check the error answer
  const constErrorCheckingAnswer = (finalData: any) => {
    for (const key in answerOptionState) {
      if (key == selectedType.type) {
        if (
          isAnswerSetValid({
            answerSet: (finalData as any)[key],
            type: selectedType.type,
          })
        ) {
          setAnswerError(null);
        } else {
          setAnswerError(t("REQUIRE.ANSWER_FIELD"));
        }
      }
    }
  };

  const handleAttachment = (id: any, file: File) => {
    answerOptionSetState((prevState: any) => {
      const updateData = {
        ...prevState,
        [selectedType.type]: prevState[selectedType.type].map((choice: any) =>
          choice.id === id
            ? { ...choice, label: choice.label, image: file }
            : choice,
        ),
      };
      constErrorCheckingAnswer(updateData);
      return updateData;
    });
  };

  const handleCheckboxChange = (id: any) => {
    answerOptionSetState((prevState: any) => {
      const updateData = {
        ...prevState,
        [selectedType.type]: prevState[selectedType.type].map((choice: any) =>
          choice.id === id
            ? { ...choice, selected: !choice.selected }
            : selectedType.type == "Single"
              ? { ...choice, selected: false }
              : choice,
        ),
      };
      return updateData;
    });
  };

  // TO ADD NEW CHOICE IN ANSWERS
  const handleAddChoice = () => {
    const newChoice = {
      id: `choice-${Math.random()}`,
      label: "",
      selected: false,
    };
    if (checkShouldAddInputValue(answerOptionState, selectedType.type)) {
      answerOptionSetState((prevState: any) => ({
        ...prevState,
        [selectedType.type]: [...prevState[selectedType.type], newChoice],
      }));
    }
  };

  const handleDeleteOption = (id: any) => {
    answerOptionSetState((prevState: any) => {
      const updateData = {
        ...prevState,
        [selectedType.type]:
          prevState[selectedType.type].length === 1
            ? prevState[selectedType.type]
            : prevState[selectedType.type].filter(
                (choice: any) => choice.id !== id,
              ),
      };
      constErrorCheckingAnswer(updateData);
      return updateData;
    });
  };

  // TO HANDLE ADD BUTTON
  const handleAddClick = () => {
    setIsQuestionAnsEdited(false);
    for (const key in answerOptionState) {
      if (key == selectedType.type) {
        addBtnCallBack({
          answerSet: (answerOptionState as any)[key],
          type: selectedType.type,
        });
      }
    }
  };
  // RENDER AS PER CONDITIONS
  const renderAnswerContent = () => {
    switch (selectedType.type) {
      case "Single":
        return (
          <>
            <div className="templateOptionScroll">
              {answerOptionState.Single.map((list: any) => (
                <>
                  <div className="selectOption" key={list.id}>
                    <div className="answerSec answerInput">
                      <CheckboxRadio
                        name="ansOptions"
                        type="radio"
                        checked={list.selected}
                        onChange={() => handleCheckboxChange(list.id)}
                      />
                      {list.image && (
                        <div className="imageAttachAnsParent">
                          <img
                            className="singleImage"
                            src={
                              (list?.image?.ImageUrl as any) instanceof Blob
                                ? URL.createObjectURL(
                                    list?.image?.ImageUrl as any,
                                  )
                                : list?.image?.ImageUrl
                            }
                            alt="Attachment"
                          />
                        </div>
                      )}

                      <InputField
                        value={list.label}
                        onChange={(e) => handleInputChange(list.id, e)}
                        placeholder={t("GENERAL.WRITE_HERE")}
                      />
                    </div>
                    <div className="answerSec">
                      <div className="attachmentIcon">
                        <label
                          className="cursorPointer"
                          htmlFor={`fileInput-${list.id}`}
                        >
                          <AttachmentIcon />
                        </label>
                        <input
                          id={`fileInput-${list.id}`}
                          type="file"
                          accept="image/*"
                          style={{ display: "none" }}
                          onChange={async (e: any) => {
                            const ImageData = await ImageUploader(
                              e,
                              `interviews/${companyId}/question/${generateUUID()}`,
                            );

                            handleAttachment(list.id, ImageData);
                            e.target.value = "";
                          }}
                        />
                      </div>
                      <div
                        style={{
                          cursor:
                            answerOptionState.Single.length === 1
                              ? "not-allowed"
                              : "pointer",
                        }}
                        onClick={() => handleDeleteOption(list.id)}
                      >
                        <DeleteIcon
                          color={
                            answerOptionState.Single.length === 1
                              ? "var(--light-grey)"
                              : "var(--secondary-red)"
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className="textEditorMsg">
                    <p className="errorMessage">
                      {list.label.length > textAnsLimit && (
                        <>
                          {t("GENERAL.MUST_BE_LESS_THAN")} {textAnsLimit}{" "}
                          {t("GENERAL.CHARACTERS")}
                        </>
                      )}
                    </p>
                    {list.label.length > 0 && (
                      <p className="limitMsg">
                        {calculateMinusAmount(list.label.length, textAnsLimit)}{" "}
                        {t("CREATE_ASSESSMENT.TEXT_EDITOR_LIMIT")}
                      </p>
                    )}
                  </div>
                </>
              ))}
            </div>
            <div className="addChoice" onClick={handleAddChoice}>
              <h6>{t("GENERAL.ADD_CHOICE")}</h6>
            </div>
          </>
        );
      case "Multiple":
        return (
          <>
            <div className="templateOptionScroll">
              {answerOptionState.Multiple.map((list: any) => (
                <>
                  <div className="selectOption" key={list.id}>
                    <div className="answerSec answerInput">
                      <CheckboxRadio
                        name="ansOptions"
                        type="checkbox"
                        checked={list.selected}
                        onChange={() => handleCheckboxChange(list.id)}
                      />
                      {list?.image?.ImageUrl && (
                        <div className="imageAttachAnsParent">
                          <img
                            className="singleImage"
                            src={
                              (list?.image?.ImageUrl as any) instanceof Blob
                                ? URL.createObjectURL(
                                    list?.image?.ImageUrl as any,
                                  )
                                : list?.image?.ImageUrl
                            }
                            alt="Attachment"
                          />
                        </div>
                      )}

                      <InputField
                        value={list.label}
                        onChange={(e) => handleInputChange(list.id, e)}
                        placeholder={t("GENERAL.WRITE_HERE")}
                      />
                    </div>
                    <div className="answerSec">
                      <div className="attachmentIcon">
                        <label
                          className="cursorPointer"
                          htmlFor={`fileInput-${list.id}`}
                        >
                          <AttachmentIcon />
                        </label>
                        <input
                          id={`fileInput-${list.id}`}
                          type="file"
                          accept="image/*"
                          style={{ display: "none" }}
                          onChange={async (e: any) => {
                            const ImageData = await ImageUploader(
                              e,
                              `interviews/${companyId}/question/${generateUUID()}`,
                            );
                            handleAttachment(list.id, ImageData);
                            e.target.value = "";
                          }}
                        />
                      </div>
                      <div
                        style={{
                          cursor:
                            answerOptionState.Multiple.length === 1
                              ? "not-allowed"
                              : "pointer",
                        }}
                        onClick={() => handleDeleteOption(list.id)}
                      >
                        <DeleteIcon
                          color={
                            answerOptionState.Multiple.length === 1
                              ? "var(--light-grey)"
                              : "var(--secondary-red)"
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className="textEditorMsg">
                    <p className="errorMessage">
                      {list.label.length > textAnsLimit && (
                        <>
                          {t("GENERAL.MUST_BE_LESS_THAN")} {textAnsLimit}{" "}
                          {t("GENERAL.CHARACTERS")}
                        </>
                      )}
                    </p>
                    {list.label.length > 0 && (
                      <p className="limitMsg">
                        {calculateMinusAmount(list.label.length, textAnsLimit)}{" "}
                        {t("CREATE_ASSESSMENT.TEXT_EDITOR_LIMIT")}
                      </p>
                    )}
                  </div>
                </>
              ))}
            </div>
            <div className="addChoice" onClick={handleAddChoice}>
              <h6>{t("GENERAL.ADD_CHOICE")}</h6>
            </div>
          </>
        );
      case "Essay":
        return (
          // JSX for Essay type
          <div>
            <InputField
              additionalProps={{
                as: "textarea",
                rows: 10,
                style: { resize: "none" },
              }}
              value={answerOptionState.Essay}
              onChange={(e) => handleInputChange(null, e)}
            />
          </div>
        );
      case "Video":
        return (
          // JSX for Video type
          <div>
            <InputField
              additionalProps={{
                as: "textarea",
                rows: 10,
                style: { resize: "none" },
              }}
              value={answerOptionState.Video}
              onChange={(e) => handleInputChange(null, e)}
            />
          </div>
        );
      default:
        return null;
    }
  };
  const handleSelect = (type: string) => {
    setSelectedType({ ...selectedType, type: type });
  };
  return (
    <div className="answerTabSec">
      <div className="answerHead">
        <div onClick={() => handleSelect("Single")}>
          <h6 className={selectedType.type === "Single" ? "activeTab" : ""}>
            {t("GENERAL.SINGLE")}
          </h6>
        </div>
        <div onClick={() => handleSelect("Multiple")}>
          <h6 className={selectedType.type === "Multiple" ? "activeTab" : ""}>
            {t("GENERAL.MULTIPLE")}
          </h6>
        </div>
        <div onClick={() => handleSelect("Essay")}>
          <h6 className={selectedType.type === "Essay" ? "activeTab" : ""}>
            {t("GENERAL.ESSAY")}
          </h6>
        </div>
        <div onClick={() => handleSelect("Video")}>
          <h6 className={selectedType.type === "Video" ? "activeTab" : ""}>
            {t("GENERAL.VIDEO")}
          </h6>
        </div>
      </div>
      <div className="answerContent">
        <h5>{t("GENERAL.FILL_ANSWER")}</h5>
        {renderAnswerContent()}
      </div>
      {answerError && <div className="errorMessage">{answerError}</div>}
      <div className="answerContentAddBtn">
        <AssessmentButton onClick={handleAddClick}>
          {isQuestionAnsEdited ? t("GENERAL.UPDATE") : t("GENERAL.ADD")}
        </AssessmentButton>
      </div>
    </div>
  );
};

export default AnswerOptions;
