// utils/validationSchemas.ts
import * as Yup from "yup";
import { useTranslation } from "react-i18next";

export const signupUserSchema = (t: ReturnType<typeof useTranslation>["t"]) =>
  Yup.object().shape({
    companyName: Yup.string().required(t("ERROR.COMPANY_NAME_REQUIRED")),
    industryType: Yup.string().required(t("ERROR.INDUSTRY_TYPE_REQUIRED")),
    displayName: Yup.string().required(t("ERROR.DISPLAY_NAME_REQUIRED")),
    websiteUrl: Yup.string()
      .url(t("ERROR.INVALID_URL"))
      .required(t("ERROR.WEBSITE_URL_REQUIRED")),
    mobile: Yup.string()
      .matches(/^[0-9]{5,20}$/, t("ERROR.INVALID_MOBILE_NUMBER"))
      .required(t("ERROR.MOBILE_REQUIRED")),
    // address: Yup.string().required(t("ERROR.ADDRESS_REQUIRED")),
    city: Yup.string().required(t("ERROR.CITY_REQUIRED")),
    country: Yup.string().required(t("ERROR.COUNTRY_REQUIRED")),
    state: Yup.string().required(t("ERROR.STATE_REQUIRED")),
    zipcode: Yup.string().required(t("ERROR.ZIPCODE_REQUIRED")),
    linkedin: Yup.string().url(t("ERROR.INVALID_URL")),
    twitter: Yup.string().url(t("ERROR.INVALID_URL")),
    facebook: Yup.string().url(t("ERROR.INVALID_URL")),
    instagram: Yup.string().url(t("ERROR.INVALID_URL")),
    ownerFirstName: Yup.string().required(t("ERROR.FNAME_REQUIRED")),
    ownerLastName: Yup.string().required(t("ERROR.LNAME_REQUIRED")),
    ownerDesignation: Yup.string().required(t("ERROR.DESIGNATION_REQUIRED")),
    ownerEmail: Yup.string()
      .matches(
        /^(?!.*\.{2})[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9]+(?:\.[a-zA-Z0-9-]+)*$/,
        t("ERROR.INVALID_EMAIL_ADDRESS"),
      )
      .required(t("ERROR.EMAIL_REQUIRED")),
    ownerMobile: Yup.string().matches(
      /^[0-9]{5,20}$/,
      t("ERROR.INVALID_MOBILE_NUMBER"),
    ),
  });
