import "./banner.scss";
import { useTranslation } from "react-i18next";
import NextArrow from "../../utils/Images/NextArrow";
import i18n from "../../i18nConfig";
import { useNavigate } from "react-router-dom";
const Banner = () => {
  const { t } = useTranslation();
  const currentLanguage = i18n.language;
  const navigate = useNavigate();
  return (
    <div className="banner-sec">
      <div className="alert alert-warning" role="alert">
        <div className="banner">
          <span>{t("GENERAL.COMPLETE_YOUR_PROFILE")}</span>
          <div
            className="createProfileArrow"
            onClick={() =>
              navigate(`/${currentLanguage}/assessment/create-profile`, {
                state: { editProfile: false },
              })
            }
          >
            <NextArrow />
          </div>
        </div>
      </div>
    </div>
  );
};
export default Banner;
