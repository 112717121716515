const SettingsIcon = () => {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.5026 8.00006C10.5026 9.10461 9.60706 9.99997 8.50248 9.99997C7.39779 9.99997 6.50235 9.10461 6.50235 8.00006C6.50235 6.89561 7.39779 6.00016 8.50248 6.00016C9.60706 6.00016 10.5026 6.89561 10.5026 8.00006Z"
        stroke="#5A5A5A"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.76819 14.0997L1.25303 8.81293C0.915656 8.29036 0.915656 7.61859 1.25303 7.09601L3.75218 1.89225C4.13474 1.32443 4.77868 0.988758 5.4633 1.00029H11.5417C12.2262 0.988758 12.8702 1.32443 13.2528 1.89225L15.747 7.09201C16.0843 7.61459 16.0843 8.28636 15.747 8.80893L13.2368 14.0997C12.8545 14.6734 12.206 15.0126 11.5167 14.9996H5.4873C4.79829 15.0123 4.15031 14.6731 3.76819 14.0997Z"
        stroke="#5A5A5A"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SettingsIcon;
