import { useTranslation } from "react-i18next";
import SmartAssessLogo from "../../../../utils/Images/SmartAssessLogo";
import ResponseVideoTypeIcon from "../../../../utils/Images/ResponseVideoTypeIcon";
import SeparateOrLine from "./SeparateOrLine";
import { useEffect, useRef, useState } from "react";
import { formatTime, roundNumber } from "../../../../utils/Common/Constant";
import PlayVideoIcon from "../../../../utils/Images/PlayVideoIcon";
import PauseVideoIcon from "../../../../utils/Images/PauseVideoIcon";
import VideoMaximize from "../../../../utils/Images/VideoMaximize";
import DownArrow from "../../../../utils/Images/DownArrow";
// import PreviousArrow from "../../../../utils/Images/PreviousArrow";
import Voice_VisualAnalysis from "../EvaluationTypes/Voice_VisualAnalysis";
import BackSlideArrow from "../../../../utils/Images/BackSlideArrow";
import { AssessmentButton } from "ui-components";
interface VideoTypeProps {
  answerData: any;
  handleAIClick: any;
  isAiEvaluation: any;
  isPaused: boolean;
  isMaximize: boolean;
  isCustomQuestion: boolean;
  evaluationTypesObj: any;
  handleManualReview: any;
  isNotified?: boolean;
}
const VideoType: React.FC<VideoTypeProps> = ({
  answerData,
  handleAIClick,
  isAiEvaluation,
  isPaused,
  isMaximize,
  isCustomQuestion,
  evaluationTypesObj,
  handleManualReview,
  isNotified,
}) => {
  const [gemini, setGemini] = useState<any>(null);
  const [score, setScore] = useState<any>(null);
  const [emotionAvg, setEmotionAvg] = useState<any>(null);
  const { t } = useTranslation();
  const [isOverlayVisible, setOverlayVisible] = useState(false);
  const videoRef: any = useRef();
  const scrollRef: any = useRef();
  const [geminiDetail, setGeminiDetail] = useState<any>(null);
  const [voiceConfidence, setVoiceConfidence] = useState<number>(0);
  const [isDetailVisible, setIsDetailVisible] = useState(false);
  const [videoTime, setVideoTime] = useState(0);
  useEffect(() => {
    setVideoTime(answerData?.responseTime);
  }, [answerData, isOverlayVisible]);
  useEffect(() => {
    if (isOverlayVisible) {
      const interval = setInterval(() => {
        setVideoTime((prevTime) => {
          if (prevTime > 0) {
            return prevTime - 1;
          } else {
            clearInterval(interval);
            return 0;
          }
        });
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [isOverlayVisible]);
  useEffect(() => {
    const smartAiEvaluation = answerData?.evaluationResult?.filter(
      (el: any) => {
        return (
          el.evaluationType === "smartAi_all" ||
          el.evaluationType === "smart_correctness_visual" ||
          el.evaluationType === "smart_correctness_audio" ||
          el.evaluationType === "smart_correctness"
        );
      },
    );

    if (smartAiEvaluation && smartAiEvaluation.length > 0) {
      const { evaluation, score } = smartAiEvaluation[0];
      if (
        evaluation &&
        Object.prototype.hasOwnProperty.call(evaluation, "gemini") &&
        Object.prototype.hasOwnProperty.call(evaluation, "smartAi")
      ) {
        const { gemini, smartAi } = evaluation;
        if (gemini && smartAi) {
          const emotionAvg = smartAi?.face_emotions_cont?.["Emotion Averages"];
          setGemini(gemini);
          setScore(score);
          setEmotionAvg(emotionAvg);
          setVoiceConfidence(smartAi?.voice_confidence);
        }
      }
    } else {
      setGemini(null);
      setScore(null);
      setEmotionAvg(null);
    }
    setOverlayVisible(false);
  }, [answerData, isPaused]);
  const handlePlayClick = () => {
    !isOverlayVisible ? videoRef?.current?.play() : videoRef?.current?.pause();
  };
  const handleMaximize = () => {
    videoRef.current.requestFullscreen();
  };
  const handleSlideLeft = (detail: any, parameter: string) => {
    setGeminiDetail({ detail, parameter: parameter });
    setIsDetailVisible(true);
  };
  useEffect(() => {
    !isAiEvaluation?.isEvaluation && setIsDetailVisible(false);
  }, [isAiEvaluation]);
  return (
    <div className="mainVideoFrame">
      <span>{t("RESPONSE_LIST.ANSWER")} :</span>
      <div className="videoTypeQuestion">
        <div
          className={`videoContainer ${isOverlayVisible && "playVideoContainer"}`}
        >
          <div>
            <div className="maximizeIcon" onClick={handleMaximize}>
              <p>{formatTime(videoTime)}</p>
              <VideoMaximize />
            </div>
          </div>
          <div className="playPauseIcon" onClick={handlePlayClick}>
            {!isOverlayVisible ? (
              <div className="playSvg">
                <PlayVideoIcon />
              </div>
            ) : (
              <PauseVideoIcon />
            )}
          </div>
          <video
            onPlay={() => setOverlayVisible(!isOverlayVisible)}
            onPause={() => {
              setOverlayVisible(!isOverlayVisible);
            }}
            ref={videoRef}
            className="videoCss"
            src={answerData?.response?.answerVideoUrl}
          >
            {t("RESPONSE_LIST.VIDEO_DOSE_NOT_SUPPORT")}
          </video>
        </div>
      </div>
      {/* here this div will not visible when isCustomeQuestion is true */}
      {!isCustomQuestion && (
        <div
          className={`result-slider ${isMaximize && "result-slider-Maximize"}`}
          ref={scrollRef}
        >
          <div className={`aiEvalution ${isMaximize && "aiEvalutionMaximize"}`}>
            {answerData?.status !== "skipped" &&
              answerData?.isCustomQuestion === false &&
              answerData?.type === "Video" && (
                <>
                  <div className="logoResult mt-5">
                    <div className="smartAssessLogo">
                      <SmartAssessLogo
                        color={
                          isAiEvaluation?.isEvaluation && !isNotified
                            ? "var(--primary-green)"
                            : "var(--icons-grey)"
                        }
                        width="28"
                        height="28"
                      />
                      <span>{t("RESPONSE_LIST.AI_ANALYSIS")}</span>
                    </div>
                    <div
                      className={`resultStatus ${isAiEvaluation?.isEvaluation && !isNotified ? "activeAIScore" : "inActiveAIScore"}`}
                    >
                      <p className="">
                        {t("GENERAL.SCORE")} -{" "}
                        <span>{score ? score : "0"}%</span>
                      </p>
                    </div>
                  </div>
                  <div className="overallSummary">
                    <span>
                      {(gemini && gemini?.overall_summary?.summary) ||
                        gemini?.overall_summary}
                    </span>
                  </div>
                  <div className="videoTypeEvalution">
                    <div
                      onClick={handleAIClick}
                      className="ResponseVideoTypeIcon"
                    >
                      <ResponseVideoTypeIcon
                        color={
                          isAiEvaluation?.isEvaluation && !isNotified
                            ? "var(--primary-green)"
                            : "var(--icons-grey)"
                        }
                      />
                    </div>
                    <div
                      className={`videoAnalysis ${isAiEvaluation?.isEvaluation && !isNotified ? "activeAiSection" : "inactiveAiSection"}`}
                    >
                      {!isDetailVisible ? (
                        <div className="row">
                          <div className="col-sm-12 col-md-10 col-lg-3">
                            {!gemini?.positivity ? (
                              <div
                                className="analysisSub mb-4"
                                onClick={() => {
                                  handleSlideLeft(
                                    gemini?.accuracy,
                                    t("RESPONSE_LIST.CORRECTNESS"),
                                  );
                                }}
                              >
                                <div className="analysis-title">
                                  <p>{t("RESPONSE_LIST.CORRECTNESS")}</p>
                                  <div className="detail-arrow">
                                    <DownArrow color={"var(--darker-grey)"} />
                                  </div>
                                </div>
                                <span>
                                  {evaluationTypesObj?.attributes?.aiAnalysis
                                    ? `${gemini?.accuracy?.percentage || 0}%`
                                    : // : t("GENERAL.NA")
                                      "-"}
                                </span>
                              </div>
                            ) : (
                              <div className="analysisSub mb-4">
                                <div
                                  className="analysis-title"
                                  onClick={() => {
                                    handleSlideLeft(
                                      gemini?.positivity,
                                      t("RESPONSE_LIST.POSITIVITY"),
                                    );
                                  }}
                                >
                                  <p>{t("RESPONSE_LIST.POSITIVITY")}</p>
                                  <div className="detail-arrow">
                                    <DownArrow color={"var(--darker-grey)"} />
                                  </div>
                                </div>
                                <span>
                                  {evaluationTypesObj?.attributes?.aiAnalysis
                                    ? `${gemini?.positivity?.percentage || 0}%`
                                    : // : t("GENERAL.NA")
                                      "-"}
                                </span>
                              </div>
                            )}
                          </div>
                          <div className="col-sm-12 col-md-10 col-lg-3">
                            <div
                              className="analysisSub mb-4"
                              onClick={() => {
                                handleSlideLeft(
                                  gemini?.completeness,
                                  t("RESPONSE_LIST.COMPLETENESS"),
                                );
                              }}
                            >
                              <div className="analysis-title">
                                <p>{t("RESPONSE_LIST.COMPLETENESS")}</p>
                                <div className="detail-arrow">
                                  <DownArrow color={"var(--darker-grey)"} />
                                </div>
                              </div>
                              <span>
                                <span>
                                  {evaluationTypesObj?.attributes?.aiAnalysis
                                    ? `${gemini?.completeness?.percentage || 0}%`
                                    : // : t("GENERAL.NA")
                                      "-"}
                                </span>
                              </span>
                            </div>
                          </div>
                          <div className="col-sm-12 col-md-10 col-lg-3">
                            <div
                              className="analysisSub mb-4"
                              onClick={() => {
                                handleSlideLeft(
                                  gemini?.clarity,
                                  t("RESPONSE_LIST.CLARITY"),
                                );
                              }}
                            >
                              <div className="analysis-title">
                                <p>{t("RESPONSE_LIST.CLARITY")}</p>
                                <div className="detail-arrow">
                                  <DownArrow color={"var(--darker-grey)"} />
                                </div>
                              </div>
                              <span>
                                {evaluationTypesObj?.attributes?.aiAnalysis
                                  ? `${gemini?.clarity?.percentage || 0}%`
                                  : // : t("GENERAL.NA")
                                    "-"}
                              </span>
                            </div>
                          </div>
                          <div className="col-sm-12 col-md-10 col-lg-3">
                            <div
                              className="analysisSub mb-4"
                              onClick={() => {
                                handleSlideLeft(
                                  gemini?.relevance,
                                  t("RESPONSE_LIST.RELEVANCE"),
                                );
                              }}
                            >
                              <div className="analysis-title">
                                <p>{t("RESPONSE_LIST.RELEVANCE")}</p>
                                <div className="detail-arrow">
                                  <DownArrow color={"var(--darker-grey)"} />
                                </div>
                              </div>
                              <span>
                                {evaluationTypesObj?.attributes?.aiAnalysis
                                  ? `${gemini?.relevance?.percentage || 0}%`
                                  : // : t("GENERAL.NA")
                                    "-"}
                              </span>
                            </div>
                          </div>
                          <div className="col-sm-12 col-md-10 col-lg-3">
                            <div
                              className="analysisSub mb-4"
                              onClick={() => {
                                handleSlideLeft(
                                  gemini?.depth,
                                  t("RESPONSE_LIST.DEPTH"),
                                );
                              }}
                            >
                              <div className="analysis-title">
                                <p>{t("RESPONSE_LIST.DEPTH")}</p>
                                <div className="detail-arrow">
                                  <DownArrow color={"var(--darker-grey)"} />
                                </div>
                              </div>
                              <span>
                                {evaluationTypesObj?.attributes?.aiAnalysis
                                  ? `${gemini?.depth?.percentage || 0}%`
                                  : // : t("GENERAL.NA")
                                    "-"}
                              </span>
                            </div>
                          </div>
                          <div className="col-sm-12 col-md-10 col-lg-3">
                            <div
                              className="analysisSub mb-4"
                              onClick={() => {
                                handleSlideLeft(
                                  gemini?.language_and_grammar,
                                  t("RESPONSE_LIST.COMMUNICATION"),
                                );
                              }}
                            >
                              <div className="analysis-title">
                                <p>{t("RESPONSE_LIST.COMMUNICATION")}</p>
                                <div className="detail-arrow languageArrow">
                                  <DownArrow color={"var(--darker-grey)"} />
                                </div>
                              </div>
                              <span>
                                {evaluationTypesObj?.attributes?.aiAnalysis
                                  ? `${gemini?.language_and_grammar?.percentage || 0}%`
                                  : // : t("GENERAL.NA")
                                    "-"}
                              </span>
                            </div>
                          </div>
                          <div className="col-sm-12 col-md-10 col-lg-3">
                            <div
                              className="analysisSub mb-4"
                              onClick={() => {
                                handleSlideLeft(
                                  null,
                                  t("RESPONSE_LIST.VISUAL_ANALYSIS"),
                                );
                              }}
                            >
                              <div className="analysis-title">
                                <p>{t("RESPONSE_LIST.VISUAL_ANALYSIS")}</p>
                                <div className="detail-arrow">
                                  <DownArrow color={"var(--darker-grey)"} />
                                </div>
                              </div>
                              <span>
                                {evaluationTypesObj?.attributes?.visualAnalysis
                                  ? `${roundNumber(emotionAvg?.CALM) || 0}%`
                                  : // : t("GENERAL.NA")
                                    "-"}
                              </span>
                            </div>
                          </div>
                          <div className="col-sm-12 col-md-10 col-lg-3">
                            <div
                              className="analysisSub mb-4"
                              onClick={() => {
                                handleSlideLeft(
                                  null,
                                  t("RESPONSE_LIST.VOICE_ANALYSIS"),
                                );
                              }}
                            >
                              <div className="analysis-title">
                                <p>{t("RESPONSE_LIST.VOICE_ANALYSIS")}</p>
                                <div className="detail-arrow">
                                  <DownArrow color={"var(--darker-grey)"} />
                                </div>
                              </div>
                              <span>
                                {" "}
                                {evaluationTypesObj?.attributes?.audioAnalysis
                                  ? `${Math.round(voiceConfidence * 100) || 0}%`
                                  : // : t("GENERAL.NA")
                                    "-"}
                              </span>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div
                          className={`description-box ${isDetailVisible && "show"}`}
                        >
                          <div className="d-flex gap-3">
                            <div
                              onClick={() => {
                                setIsDetailVisible(false);
                              }}
                              className="backArrow"
                            >
                              <BackSlideArrow />
                            </div>
                            <div>
                              <span className="parameter-title">
                                {geminiDetail?.parameter}{" "}
                              </span>
                              <span className="parameter-percentage">
                                {geminiDetail?.detail &&
                                  `(${geminiDetail?.detail?.percentage}%)`}
                                {geminiDetail?.parameter === "Voice Analysis" &&
                                  evaluationTypesObj?.attributes
                                    ?.audioAnalysis &&
                                  `(${Math.round(voiceConfidence * 100) ?? "-"}%)`}
                              </span>
                            </div>
                          </div>
                          {geminiDetail?.parameter !== "Visual Analysis" &&
                            geminiDetail?.parameter !== "Voice Analysis" &&
                            (geminiDetail?.detail ? (
                              <>
                                <div>
                                  <span className="parameter-sub-heading">
                                    {t("RESPONSE_LIST.AI_ANALYSIS")}:{" "}
                                  </span>
                                  <span className="parameter-description">
                                    {geminiDetail?.detail?.reasoning ?? "-"}
                                  </span>
                                </div>
                                <div>
                                  <span className="parameter-sub-heading">
                                    {" "}
                                    {t("RESPONSE_LIST.LACKING")}:{" "}
                                  </span>
                                  <span className="parameter-description">
                                    {geminiDetail?.detail?.deduction_reason ??
                                      "-"}
                                  </span>
                                </div>
                              </>
                            ) : (
                              <span className="parameter-sub-heading">
                                {/* {t("GENERAL.NA")} */}
                                <div className="summaryNotAvail">
                                  <span>{t("RESPONSE_LIST.SUMMARY_NOT")}</span>
                                </div>
                              </span>
                            ))}
                          {geminiDetail?.parameter === "Visual Analysis" && (
                            <div className="smartAiParam">
                              <Voice_VisualAnalysis
                                t={t}
                                evaluationSummary={emotionAvg}
                                isVisualAnalysis={
                                  evaluationTypesObj?.attributes?.visualAnalysis
                                }
                              />
                            </div>
                          )}
                          {geminiDetail?.parameter === "Voice Analysis" && (
                            <div className="summaryNotAvail">
                              <span>{t("RESPONSE_LIST.SUMMARY_NOT")}</span>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </>
              )}
          </div>
        </div>
      )}
      {isAiEvaluation?.isEvaluation && !isNotified && (
        <div className="aiSaveBtn">
          <AssessmentButton onClick={handleManualReview} disabled={isNotified}>
            {t("GENERAL.SAVE")}
          </AssessmentButton>
        </div>
      )}
      {answerData?.status !== "skipped" &&
        answerData?.isCustomQuestion === false &&
        answerData?.type === "Video" && <SeparateOrLine />}
    </div>
  );
};
export default VideoType;
