import CircleCrossIcon from "../../../../utils/Images/CircleCrossIcon";
import "./SidebarCategory.scss";
import {
  AssessmentCheckboxRadio,
  showToast,
  TemplateSearch,
} from "ui-components";
import { useTranslation } from "react-i18next";
import SkillCard from "../../../../components/SkillCard/SkillCard";
import { useEffect, useState } from "react";
import {
  getDefaultSkill,
  getIndustrySkillMapping,
  getSkillDetails,
} from "../../../../service/Scope/Scope";
import useDebounce from "../../../../utils/Hooks/useDebounce";
import { setSessionStorageItem } from "../../../../utils/SessionStorageMethod/setSessionStorageItem";
import { getSessionStorageItem } from "../../../../utils/SessionStorageMethod/getSessionStorageItem";
import {
  checkUniqueIdMatch,
  drafteditedformData,
  editedformData,
  groupBySkillId,
} from "../../../../utils/Common/Constant";
import SetDraftJsonInSession from "../../AssessmentDraftJson/SetDraftJsonInSession";
import { checkKeysInSessionStorage } from "../../../../utils/Common/checkKeysInSessionStorage";
import { useLocation, useParams } from "react-router-dom";
import DownArrow from "../../../../utils/Images/DownArrow";
import CustomModal from "../../../../components/CustomModal/CustomModal";
import { removeSessionStorageItem } from "../../../../utils/SessionStorageMethod/removeSessionStorageItem";
import { getCreateAssessmentDraftJson } from "../../../../service/DraftJson/DraftJson";
import { getAssessmentDetailsByAssessementId } from "../../../../service/SubmitAssessment/SubmitAssessment";

const sortDifficultyLevels = (levels: any) => {
  const order = ["Beginner", "Intermediate", "Expert"];
  return levels
    .split(",")
    .sort((a: any, b: any) => order.indexOf(a.trim()) - order.indexOf(b.trim()))
    .join(",");
};

const processData = (data: any) => {
  const updateData = (data ?? [])?.map((item: any) => ({
    ...item,
    difficultyLevels: sortDifficultyLevels(item?.difficultyLevels ?? ""),
  }));
  return updateData;
};

const areAllExistingDataInUpdateSkill = (
  storedSkills: any,
  existingPreviewData: any,
) => {
  return existingPreviewData.some((existingItem: any) =>
    storedSkills.some((updatedItem: any) => updatedItem.id === existingItem.id),
  );
};

const SidebarCategory = () => {
  const { t } = useTranslation();
  const editedParams = new URLSearchParams(location.search).get("edited");
  const userId = localStorage.getItem("companyId") ?? "";
  const { tempUniqueId } = useParams();
  const { pathname } = useLocation();
  const tempUniqueIdBySession = localStorage.getItem("tempUniqueId") ?? null;
  const [category, setCategory] = useState(
    getSessionStorageItem("category") ?? [],
  );
  const [selectedCategory, setSelectedCategory] = useState<any>(
    getSessionStorageItem("selectedCategory") ?? [],
  );
  const [selectedFilter, setSelectedFilter] = useState<any>(
    getSessionStorageItem("selectedFilter") ?? [],
  );
  const [globalSearch, setGlobalSearch] = useState<string>("");
  const searchGlobalSkill = useDebounce(globalSearch.trim(), 700);
  const [skill, setSkill] = useState<any>(
    getSessionStorageItem("ownPreview") ?? [],
  );
  const [addedSkills, setAddedSkills] = useState<any>(
    getSessionStorageItem("ownPreview") ?? [],
  );
  const [isAddedSKillPopupOpen, setIsAddedSKillPopupOpen] = useState({
    isPopupOpen: false,
    popupData: {},
  });

  const [questionTypeCategory, setQuestionTypeCategory] = useState(
    getSessionStorageItem("questionTypeCategory") ?? [
      { id: 1, selected: false, questionType: "Video" },
      { id: 2, selected: false, questionType: "MCQ" },
      { id: 3, selected: false, questionType: "Coding" },
      { id: 4, selected: false, questionType: "Essay" },
    ],
  );
  const [isSameSkillAdded, setIsSameSkillAdded] = useState(false);
  const [isFirstEffectDone, setIsFirstEffectDone] = useState(false);
  // First useEffect
  useEffect(() => {
    const isKeyPresent = checkKeysInSessionStorage(
      [
        "ownPreview",
        "Type",
        "titleHeadContent",
        "existingPreview",
        "existingData",
      ],
      "any",
    );

    if (
      (!isKeyPresent && !editedParams) ||
      checkUniqueIdMatch(tempUniqueId, tempUniqueIdBySession)
    ) {
      // API call to set store value in session
      SetDraftJsonInSession(userId, tempUniqueId, pathname).then(() => {
        setIsFirstEffectDone(true); // Set flag after API call completes
      });
    } else {
      setIsFirstEffectDone(true); // Set flag if condition doesn't trigger API call
    }
  }, []);
  // edit assessment work flow
  useEffect(() => {
    (async () => {
      if (editedParams) {
        const draftRes = await getCreateAssessmentDraftJson(
          userId,
          tempUniqueId,
        );
        if (
          (Object.keys(draftRes?.data?.data).length > 0 &&
            draftRes?.data?.data?.createAssessmentJson?.build?.assessmentSkill
              ?.length) ||
          draftRes?.data?.data?.createAssessmentJson?.isStoreEditedData
        ) {
          if (
            draftRes?.data?.data?.createAssessmentJson?.build?.assessmentSkill
              ?.length
          ) {
            setSkill(
              draftRes?.data?.data?.createAssessmentJson?.build
                ?.assessmentSkill,
            );
            setAddedSkills(
              draftRes?.data?.data?.createAssessmentJson?.build
                ?.assessmentSkill,
            );
          }

          drafteditedformData(draftRes?.data?.data);
        } else {
          const editedData = await getAssessmentDetailsByAssessementId(
            tempUniqueId ?? "",
          );
          const skills = editedData?.data?.assessmentSkills?.map(
            (item: any) => {
              return {
                ...item,
                id: Math.random(),
                questionNo: item?.questionNo ?? item?.totalQuestions,
              };
            },
          );
          const updateSkill = groupBySkillId(skills);
          setSkill(updateSkill);
          setAddedSkills(updateSkill);
          setSessionStorageItem("ownPreview", skills);
          editedformData(editedData);
        }
      }
    })();
  }, []);

  useEffect(() => {
    industrySkillMapping();
  }, []);

  useEffect(() => {
    // If no skills remain, set the default skill
    if (addedSkills.length === 0) {
      const defaultSkill = getSessionStorageItem("defaultSkill");
      setSkill(defaultSkill);
    }
  }, [addedSkills]);

  useEffect(() => {
    industrySkillMapping(true, selectedCategory, globalSearch, selectedFilter);
  }, [selectedCategory, selectedFilter, searchGlobalSkill]);
  // Second useEffect, dependent on isFirstEffectDone
  useEffect(() => {
    if (isFirstEffectDone) {
      const storedSkills = getSessionStorageItem("ownPreview") ?? [];
      const existingPreviewData =
        getSessionStorageItem("existingPreview")?.subjectFieldSet ?? [];
      if (storedSkills?.length > 0 || existingPreviewData?.length > 0) {
        let updateSkill = [];
        const result = areAllExistingDataInUpdateSkill(
          storedSkills,
          existingPreviewData,
        );
        if (result) {
          updateSkill = [...storedSkills];
        } else {
          updateSkill = [...storedSkills, ...existingPreviewData];
        }
        const uniqueSkillMap = new Map();
        updateSkill.forEach((item: any) => {
          const skillItem = {
            ...item,
            skillName: item?.title ?? item?.skillName,
            skillIcon: item?.icon ?? item?.skillIcon,
            skillLevel: item?.difficultyLevel,
            type: "template",
          };

          uniqueSkillMap.set(item?.id, skillItem);
        });
        // Convert the map back to an array
        const updateSkillData = Array.from(uniqueSkillMap.values());
        setSessionStorageItem("ownPreview", processData(updateSkillData));
        setAddedSkills(processData(updateSkillData));
      }
    }
  }, [isFirstEffectDone]);

  // function for getting category
  const industrySkillMapping = async (
    isFitered: boolean = false,
    categorylist: any = [],
    search: string = "",
    filter: any = [],
  ) => {
    const resSkillmapping = await getIndustrySkillMapping(
      categorylist,
      search,
      filter,
    );
    const defaultSkillRes = await getDefaultSkill(
      localStorage.getItem("industryId") ?? "",
      search,
      1,
      100000,
      filter,
    );
    const defaultUpdateSkill = (defaultSkillRes?.data ?? [])?.map(
      (item: any, ind: number) => {
        return {
          ...item,
          difficultyLevel: item?.difficultyLevels?.split(","),
          id: Math.random() + ind,
        };
      },
    );
    if (resSkillmapping?.status === 200) {
      const categoryList = resSkillmapping?.data?.categoryList.map(
        (item: any, ind: number) => {
          return {
            id: ind,
            selected: false,
            categoryName: item?.categoryName,
            skillsCategoryId: item?.skillsCategoryId,
          };
        },
      );
      if (isFitered) {
        const updateSkill = (resSkillmapping?.data?.skillList?.data ?? []).map(
          (item: any, ind: number) => {
            const existingSkill = addedSkills.find(
              (ele: any) => ele.skillId === item.skillId,
            );

            if (existingSkill) {
              return existingSkill;
            } else {
              return {
                ...item,
                difficultyLevel: item?.difficultyLevels?.split(","),
                id: Math.random() + ind,
              };
            }
          },
        );
        if (categorylist?.length) {
          const ownPreview = getSessionStorageItem("ownPreview");
          const isOwnPreviewValid = ownPreview?.length;
          const isUpdateSkillValid = updateSkill?.length;

          const finalskill = isOwnPreviewValid
            ? (setIsSameSkillAdded(true), ownPreview)
            : isUpdateSkillValid
              ? processData(updateSkill)
              : processData(defaultUpdateSkill);

          const shouldUseUpdateSkill =
            categorylist?.length ||
            search?.length ||
            (categorylist?.length && filter?.length);
          setSessionStorageItem(
            "defaultSkill",
            processData(defaultUpdateSkill),
          );
          setSkill(
            shouldUseUpdateSkill ? processData(updateSkill) : finalskill,
          );
        } else {
          if (!categorylist?.length && !filter?.length && !search?.length) {
            setSkill(
              getSessionStorageItem("ownPreview")?.length
                ? getSessionStorageItem("ownPreview")
                : defaultUpdateSkill,
            );
          } else {
            setSkill(processData(defaultUpdateSkill));
          }
        }
      } else {
        setCategory(categoryList);
      }
    }
  };

  // category type
  const handleCheckboxChange = (id: any) => {
    setCategory((prevState: any) => {
      const updatedCategory = prevState?.map((item: any) => {
        if (item?.id === id) {
          return { ...item, selected: !item?.selected };
        }
        return item;
      });

      const newSelectedCategory = updatedCategory
        .filter((item: any) => item?.selected)
        .map((item: any) => item?.skillsCategoryId);
      setSelectedCategory(newSelectedCategory);
      return updatedCategory;
    });
  };
  // question type function
  const handlequestionTypeChange = (id: any) => {
    setQuestionTypeCategory((prevState: any) => {
      const updatedQuestionTypeCategory = prevState?.map((item: any) => {
        if (item?.id === id) {
          return { ...item, selected: !item?.selected };
        }
        return item;
      });
      // Update selectedFilter based on the new question type category state
      const newSelectedFilter = updatedQuestionTypeCategory
        .filter((item: any) => item?.selected)
        .map((item: any) => item?.questionType);
      setSelectedFilter(newSelectedFilter);
      return updatedQuestionTypeCategory;
    });
  };
  // add skill
  const handleAddSkill = (skill: any) => {
    // Remove entries with a value of 0
    skill.selectedDifficultyLevels = Object.fromEntries(
      Object.entries(skill.selectedDifficultyLevels).filter(
        ([, value]) => value !== 0,
      ),
    );
    const questionNo = skill?.selectedDifficultyLevels
      ? Object.values(skill?.selectedDifficultyLevels).reduce(
          (acc: any, value: any) => acc + value,
          0,
        )
      : skill?.totalQuestions;
    skill.questionNo = questionNo;
    if (questionNo) {
      setAddedSkills((prevState: any) => {
        const updatedSkills = [...prevState, skill];
        // Store the updated skills array in session storage
        setSessionStorageItem("ownPreview", updatedSkills);
        return updatedSkills;
      });
    } else {
      showToast(t("CREATE_ASSESSMENT.NUMBER_OF_QUESTION"));
    }
  };
  // edit added skill
  const handleAddedSkill = (UpdateSkill: any) => {
    // Remove entries with a value of 0
    UpdateSkill.selectedDifficultyLevels = Object.fromEntries(
      Object.entries(UpdateSkill.selectedDifficultyLevels).filter(
        ([, value]) => {
          return value !== 0;
        },
      ),
    );
    const questionNo = UpdateSkill?.selectedDifficultyLevels
      ? Object.values(UpdateSkill?.selectedDifficultyLevels).reduce(
          (acc: any, value: any) => acc + value,
          0,
        )
      : UpdateSkill?.totalQuestions;
    UpdateSkill.questionNo = questionNo;
    if (questionNo) {
      setAddedSkills((prevState: any) => {
        const skillExists = prevState.some(
          (ele: any) => ele?.skillId === UpdateSkill?.skillId,
        );
        let updatedSkills;
        if (skillExists) {
          updatedSkills = prevState.map((ele: any) => {
            if (ele?.skillId === UpdateSkill?.skillId) {
              return UpdateSkill;
            }
            return ele;
          });
        } else {
          updatedSkills = [...prevState, UpdateSkill];
        }

        // Store the updated skills array in session storage
        setSessionStorageItem("ownPreview", updatedSkills);
        setSkill(updatedSkills);
        setIsAddedSKillPopupOpen({ isPopupOpen: false, popupData: {} });
        return updatedSkills;
      });
    } else {
      showToast(t("CREATE_ASSESSMENT.NUMBER_OF_QUESTION"));
    }
  };
  // remove add skill
  const handleRemoveSkill = (uniqueId: number) => {
    setGlobalSearch("");
    setAddedSkills((prev: any) => {
      const updatedSkills = prev?.filter((ele: any) => ele?.id !== uniqueId);
      // Store the updated skills array in session storage
      if (isSameSkillAdded) {
        setSkill(updatedSkills);
      }
      const existingPreviewData =
        getSessionStorageItem("existingPreview")?.subjectFieldSet ?? [];
      const existingKillExist = areAllExistingDataInUpdateSkill(
        updatedSkills,
        existingPreviewData,
      );
      if (!existingKillExist) {
        removeSessionStorageItem("existingPreview");
        removeSessionStorageItem("existingData");
      }
      setSessionStorageItem("ownPreview", updatedSkills);
      return updatedSkills;
    });
    handleReset();
  };
  // click on added skill then it will envoke
  const handlePopupOpen = async (skillId: string, skillItemData: any) => {
    const getSKillRes = await getSkillDetails(skillId);
    getSKillRes.id = skillItemData?.id;
    if (
      skillItemData?.selectedDifficultyLevels &&
      Object.keys(skillItemData.selectedDifficultyLevels).length > 0
    ) {
      getSKillRes.selectedDifficultyLevels =
        skillItemData.selectedDifficultyLevels;
    } else {
      getSKillRes.selectedDifficultyLevels = {
        [skillItemData?.difficultyLevel]:
          skillItemData?.totalQuestion ?? skillItemData?.totalQuestions,
      };
    }
    const order = ["Beginner", "Intermediate", "Expert"];
    const sortedDifficultyLevels = getSKillRes.difficultyLevels
      .split(",")
      .sort((a: any, b: any) => order.indexOf(a) - order.indexOf(b))
      .join(",");

    getSKillRes.difficultyLevels = sortedDifficultyLevels;
    if (getSKillRes?.skillId) {
      setIsAddedSKillPopupOpen({
        isPopupOpen: true,
        popupData: getSKillRes,
      });
    }
  };
  // reset category or filter
  const handleReset = () => {
    setCategory((prevState: any) => {
      const updatedCategory = prevState?.map((item: any) => {
        return { ...item, selected: false };
      });
      const newSelectedCategory = updatedCategory
        .filter((item: any) => item?.selected)
        .map((item: any) => item?.skillsCategoryId);
      setSelectedCategory(newSelectedCategory);
      return updatedCategory;
    });
    setQuestionTypeCategory((prevState: any) => {
      const updatedCategory = prevState?.map((item: any) => {
        return { ...item, selected: false };
      });
      const newSelectedFilter = updatedCategory
        .filter((item: any) => item?.selected)
        .map((item: any) => item?.questionType);
      setSelectedFilter(newSelectedFilter);
      return updatedCategory;
    });
  };
  return (
    <div className="createBuildSec">
      <div className="">
        <div className="row">
          <div className="langSelectSec">
            <div className="dropFilterLeft"></div>
            <div className="langSelect">
              {addedSkills?.map((item: any, ind: number) => (
                <div key={ind} className="forModelLang">
                  <div
                    className="langContent"
                    onClick={() => handlePopupOpen(item?.skillId, item)}
                    key={ind}
                  >
                    <h3 className="langName">
                      {item?.skillName ?? item?.title} - {item?.questionType}(
                      {item?.selectedDifficultyLevels
                        ? Object.values(item?.selectedDifficultyLevels).reduce(
                            (acc: any, value: any) => acc + value,
                            0,
                          )
                        : item?.totalQuestions}
                      )
                    </h3>
                  </div>
                  <span
                    onClick={() => {
                      handleRemoveSkill(item?.id);
                    }}
                    className="crossBtn"
                  >
                    <CircleCrossIcon color="var(--primary-white)" />
                  </span>
                </div>
              ))}
            </div>
            <div className="dropFilterRight"></div>
          </div>

          <div className="col-md-3">
            <div className="categorySideBar">
              <div className="categorySearch">
                <TemplateSearch
                  onChange={(e) => {
                    setGlobalSearch(e);
                  }}
                  placeholder={t("CREATE_ASSESSMENT.SKILL_SEARCH_PLACEHOLDER")}
                  value={globalSearch}
                  handleKeyPress={() => {}}
                  isCrossIconVisible={(globalSearch && true) || false}
                  onCrossPress={() => setGlobalSearch("")}
                />
              </div>
              <div
                onClick={() => handleReset()}
                className={
                  selectedCategory?.length || selectedFilter?.length
                    ? "categoryResetBtn"
                    : "categoryResetBtn pointerEventsResetBtn"
                }
              >
                {t("GENERAL.RESET")}
              </div>
              <div className="categoryAccord">
                <div className="accordion" id="accordionPanelsStayOpenExample">
                  <div className="accordion-item">
                    <h2 className="accordion-header">
                      <button
                        className="accordion-button"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapseOne"
                        aria-expanded="true"
                        aria-controls="panelsStayOpen-collapseOne"
                      >
                        {t("CREATE_ASSESSMENT.GLOBAL_CATEGORY")}
                        <span>
                          <DownArrow />
                        </span>
                      </button>
                    </h2>
                    <div
                      id="panelsStayOpen-collapseOne"
                      className="accordion-collapse collapse show"
                    >
                      <div className="accordion-body">
                        {category?.map((list: any, key: number) => (
                          <div
                            key={key}
                            className="technicalSec"
                            onClick={() => handleCheckboxChange(list?.id)}
                          >
                            <AssessmentCheckboxRadio
                              type="checkbox"
                              checked={list?.selected}
                            />
                            <label>{list?.categoryName}</label>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className="accordion-item">
                    <h2 className="accordion-header">
                      <button
                        className="accordion-button"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapseTwo"
                        aria-expanded="true"
                        aria-controls="panelsStayOpen-collapseTwo"
                      >
                        {t("CREATE_ASSESSMENT.QUESTION_TYPE")}
                        <span>
                          <DownArrow />
                        </span>
                      </button>
                    </h2>
                    <div
                      id="panelsStayOpen-collapseTwo"
                      className="accordion-collapse collapse show"
                    >
                      <div className="accordion-body">
                        {questionTypeCategory?.map((list: any, key: number) => (
                          <div
                            key={key}
                            className="technicalSec"
                            onClick={() => handlequestionTypeChange(list?.id)}
                          >
                            <AssessmentCheckboxRadio
                              type="checkbox"
                              checked={list?.selected}
                            />
                            <label>{list?.questionType}</label>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-9">
            <div className="addingLangSection">
              <div className="row">
                {skill?.length > 0 ? (
                  skill?.map((item: any, ind: number) => (
                    <div key={ind} className="col-sm-12 col-md-6 col-lg-4">
                      <SkillCard
                        skillItem={item}
                        textBtn={t("GENERAL.ADD")}
                        handleAddSkill={handleAddSkill}
                        isAdded={addedSkills?.some(
                          (skill: any) => skill?.skillId === item?.skillId,
                        )}
                      />
                    </div>
                  ))
                ) : (
                  <div className="noDataAvailabel">
                    <h1>{t("GENERAL.NO_SKILLS_FOUND")}</h1>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {isAddedSKillPopupOpen?.isPopupOpen && (
        <CustomModal
          show={isAddedSKillPopupOpen?.isPopupOpen}
          handleClose={() =>
            setIsAddedSKillPopupOpen({ isPopupOpen: false, popupData: {} })
          }
          isCrossBtn={true}
          isHeaderVisible={false}
          isFooterVisible={false}
        >
          <div className="updateSkillData">
            <SkillCard
              skillItem={isAddedSKillPopupOpen?.popupData}
              handleAddSkill={handleAddedSkill}
              textBtn={t("GENERAL.UPDATE")}
            />
          </div>
        </CustomModal>
      )}
    </div>
  );
};

export default SidebarCategory;
