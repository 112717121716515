import { NavLink, useLocation, useParams } from "react-router-dom";
import "./header.scss";
import { useTranslation } from "react-i18next";
import { AssessmentButton } from "ui-components";
import { assessmentDraftJson } from "../../../context/CreateAssessmentDraftJson/CreateAssessmentDraftJson";
import { checkActive, foundUniqueId } from "../../../utils/Common/Constant";
import ProfileSettings from "./ProfileSettings";
import { getCompanyProfileDetails } from "../../../service/Scope/Scope";
import { useEffect, useState } from "react";
import Banner from "../../Banner/Banner";
import LogoTagline from "../../../utils/Images/LogoTagline";
const Header = () => {
  const finalData = assessmentDraftJson();
  const { tempAssessmentUId } = finalData;
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const { pathname } = useLocation();
  const { tempUniqueId } = useParams();
  const email = localStorage.getItem("email");
  const companyUserId = localStorage.getItem("companyUserId");
  const [profileData, setProfileData] = useState<any>(null);
  const getProfileDetails = async () => {
    const res = await getCompanyProfileDetails(companyUserId);
    if (res?.status === 200) {
      setProfileData(res?.data?.data);
    }
  };
  useEffect(() => {
    getProfileDetails();
  }, []);
  return (
    <>
      <nav className="navbar navbar-expand-lg assessmentHeaderParent">
        <div className="container-fluid logo-link">
          <div className="logo">
            <LogoTagline />
          </div>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0 link">
              <li className="nav-item">
                <NavLink
                  className={({ isActive }) =>
                    isActive ? "nav-link addBottomBorder" : "nav-link"
                  }
                  to={`/${currentLanguage}/assessment/list`}
                >
                  {t("CREATED_ASSESSMENT_LIST_AND_REVIEW.ASSESSMENT")}
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  className={`nav-link ${checkActive(pathname, tempUniqueId, currentLanguage) ? "active" : ""}`}
                  to={`/${currentLanguage}/assessment/template/${foundUniqueId(tempAssessmentUId)}`}
                >
                  <AssessmentButton
                    className={`lightBtn ${checkActive(pathname, tempUniqueId, currentLanguage) ? "activeCreateBtn" : ""}`}
                  >
                    {t("CREATE_ASSESSMENT.TITLE")}
                  </AssessmentButton>
                </NavLink>
              </li>
            </ul>
            <ProfileSettings email={email} profileData={profileData} />
          </div>
        </div>
      </nav>
      {!profileData?.isActive && <Banner />}
      <div className="borderBottomLine"></div>
    </>
  );
};

export default Header;
