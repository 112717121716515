import { uploadMultiPart } from "smartassess-api-lib";

export const ImageUploader = async (e: any, path?: string) => {
  const fileData = [];
  for (let i = 0; i < e.target.files.length; i += 1) {
    fileData.push(e.target.files[i]);
  }
  const jsonData = {
    file: fileData,
    path: path,
    startUploadEndPoint: "s3Upload/startUpload",
    getPresignedEndPoint: "s3Upload/generatePresigned",
    completeUploadEndPoint: "s3Upload/completeUpload",
  };
  const imageData = await uploadMultiPart(jsonData);
  e.target.value = "";
  return imageData?.data?.data;
};
