import { AssessmentButton, showToast } from "ui-components";
import { convertDate, copyText } from "../../../utils/Common/Constant";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import UserImage from "../../../utils/Images/UserImage";
import { notifyAnApplicant } from "../../../service/Scope/Scope";
import { useState } from "react";
import NotifyConfirmPopup from "../CreateAssessmentResponseList/NotifyConfirmPopup/NotifyConfirmPopup";
interface ContentList {
  assessmentApplicantList: any;
  assessmentDetail: any;
  setIsNotified: any;
}
const ContentList: React.FC<ContentList> = ({
  assessmentApplicantList,
  assessmentDetail,
  setIsNotified,
}) => {
  const { t, i18n } = useTranslation();
  // Get current language
  const currentLanguage = i18n.language;
  const navigate = useNavigate();
  const assessmentId = assessmentDetail?.assessmentId;
  const companyUserId: any = localStorage.getItem("companyUserId");
  const [notifyBtnDisable, setNotifyBtnDisable] = useState(false);
  const [applicantId, setApplicantId] = useState(() => {
    const applicantId = new URLSearchParams(location.search).get("apllid");
    return applicantId ? applicantId : "";
  });
  const [isNotifyPopupVisible, setIsNotifyPopupVisible] = useState(() => {
    const notifyConfirmation = new URLSearchParams(location.search).get(
      "notifyConfirmation",
    );
    return notifyConfirmation ? JSON.parse(notifyConfirmation) : false;
  });
  const checkedStatus = (status: string, applicantId: string) => {
    if (status !== "Submitted" && status !== "AbSubmit") {
      return showToast("Only submitted assessments can be viewed", "success");
    }
    navigate(
      `/${currentLanguage}/assessment/response/${assessmentId}?aplid=${applicantId}`,
    );
  };
  // review status functional work
  const reviewStatusCheck = (
    status: string,
    resultStatus: string | null,
    finalScore: any,
    evaluationPending: boolean,
  ) => {
    if (status === "New" || status === "Deferred" || status === "Initiated") {
      return "-";
    } else if (finalScore === null) {
      if (evaluationPending) {
        return "AI Evaluation in progress";
      } else {
        return "Manual score pending";
      }
    } else if (resultStatus === null) {
      return "Selection Pending";
    } else {
      return resultStatus;
    }
  };
  //this function used to open notify confirmation popup
  const onNotifyClick = (applicantId: any) => {
    const currentUrl = window.location.href;
    const url = new URL(currentUrl);
    url.searchParams.set("notifyConfirmation", "true");
    url.searchParams.set("apllid", applicantId);
    history.replaceState({}, "", url.toString());
    setIsNotifyPopupVisible(true);
    setApplicantId(applicantId);
  };
  //this function used to notify applicant
  const notifyAnApplicantApi = async () => {
    setNotifyBtnDisable(true);
    const data = {
      companyUserId,
      assessmentId: assessmentDetail?.assessmentId,
      applicantId,
    };
    try {
      const res = await notifyAnApplicant(data);
      if (res?.status === 200) {
        const currentUrl = window.location.href;
        const url = new URL(currentUrl);
        url.searchParams.set("notifyConfirmation", "false");
        history.replaceState({}, "", url.toString());
        const isPopUpOpen: any = new URLSearchParams(location.search).get(
          "notifyConfirmation",
        );
        setIsNotifyPopupVisible(JSON.parse(isPopUpOpen));
        showToast(res?.customMessage, "success");
        setIsNotified(Math.random() * 10);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setNotifyBtnDisable(false);
    }
  };
  return (
    <>
      {assessmentApplicantList?.map((item: any, index: number) => {
        return (
          <div className={"userDetailsSect"} key={index}>
            <div className="userDetailsSection" key={index}>
              <div className="userImageDiv">
                <div
                  className={`userImageBorder ${(item?.applicationStatus === "Submitted" || item?.applicationStatus === "AbSubmit") && "pointerCursor"}`}
                  onClick={() => {
                    (item?.applicationStatus === "Submitted" ||
                      item?.applicationStatus === "AbSubmit") &&
                      checkedStatus(item?.applicationStatus, item?.applicantId);
                  }}
                >
                  {item?.profilePic === null ? (
                    <UserImage />
                  ) : (
                    <img src={item?.profilePic} alt="" />
                  )}
                </div>
              </div>
              <div className="heading userEmailDiv">
                <h3
                  className="userName"
                  onClick={() => {
                    checkedStatus(item?.applicationStatus, item?.applicantId);
                  }}
                >
                  {item?.displayName ?? "-"}
                </h3>
                <h5
                  title={item?.emailId}
                  onClick={() => copyText(item?.emailId)}
                >
                  {item?.emailId ?? "-"}
                </h5>
                <h5>{item?.mobile ?? "-"}</h5>
              </div>
              <div className="heading">
                <h4>{t("GENERAL.ASSESSMENT.STATUS")}</h4>
                <h3>{item?.applicationStatus ?? "-"}</h3>
              </div>
              <div className="heading">
                <h4>{t("GENERAL.STATUS")}</h4>
                <h3>
                  {reviewStatusCheck(
                    item?.applicationStatus,
                    item?.resultStatus,
                    item?.finalScore,
                    item?.evaluationPending,
                  )}
                </h3>
              </div>
              <div className="heading ">
                <h4>{t("GENERAL.SCORE")}</h4>
                <div className="d-flex align-items-center gap-2">
                  <div>
                    <span
                      className={`${
                        item?.finalScore !== null
                          ? item.finalScore > assessmentDetail?.cutOff
                            ? "greenBorder"
                            : item.finalScore < assessmentDetail?.cutOff
                              ? "redBorder"
                              : "orangeBorder"
                          : ""
                      }`}
                    >
                      {item?.finalScore !== null ? item?.finalScore + "%" : "-"}
                    </span>{" "}
                    <br />
                    <span className="remarkText">{item?.remark}</span>
                  </div>
                </div>
              </div>

              <div className="heading ">
                <div className="dateSec">
                  <p>{convertDate(item?.updatedAt, false, true)}</p>
                </div>
              </div>
            </div>
            <div className="viewSection">
              <div className="notified-text">
                {item?.isNotified && <span>{t("GENERAL.NOTIFY")}</span>}
              </div>
              <div className="btnRowNotify">
                {!item?.isNotified &&
                  (item?.resultStatus === "Selected" ||
                    item?.resultStatus === "Rejected") && (
                    <AssessmentButton
                      className={`viewBtn ${notifyBtnDisable && "notifyBtnDisable"}`}
                      disabled={notifyBtnDisable}
                      onClick={() => {
                        onNotifyClick(item?.applicantId);
                      }}
                    >
                      {t("GENERAL.NOTIFY_TEXT")}
                    </AssessmentButton>
                  )}
                {(item?.applicationStatus === "Submitted" ||
                  item?.applicationStatus === "AbSubmit") && (
                  <AssessmentButton
                    className="viewBtn"
                    onClick={() => {
                      checkedStatus(item?.applicationStatus, item?.applicantId);
                    }}
                  >
                    {t("GENERAL.VIEW")}
                  </AssessmentButton>
                )}
              </div>
            </div>
          </div>
        );
      })}
      {isNotifyPopupVisible && (
        <NotifyConfirmPopup
          isPopupShow={isNotifyPopupVisible}
          setIsClosePopup={setIsNotifyPopupVisible}
          handleYesPress={notifyAnApplicantApi}
          isDisable={notifyBtnDisable}
        />
      )}
    </>
  );
};

export default ContentList;
