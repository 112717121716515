import AiStamp from "../../utils/Images/AiStamp";
import "./AiConfidenceStamp.scss";
interface props {
  width?: string;
  height?: string;
  score?: any;
}
const AiConfidenceStamp = ({ width, height, score }: props) => {
  return (
    <div className="aiStamp">
      <AiStamp height={height} width={width} />
      <div className="aiConfidenceMark">
        <span>{score}%</span>
      </div>
    </div>
  );
};

export default AiConfidenceStamp;
