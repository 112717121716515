import { TextEditor, AttachmentIcon, TypeSelect } from "ui-components";
import "./TemplateBottomLeftSide.scss";
import { useTranslation } from "react-i18next";
import AnswerOptions from "../AnswerOptions/AnswerOptions";
import { setSessionStorageItem } from "../../../../../utils/SessionStorageMethod/setSessionStorageItem";
import { AssessmentJsonContext } from "../../../../../context/JsonContext/JsonContext";
import { useContext, useState } from "react";
import { ImageUploader } from "../../../../../utils/Common/MediaUploader";
import {
  addDisableCss,
  calculateMinusAmount,
  generateUUID,
  isAnswerSetValid,
  minuteOptions,
} from "../../../../../utils/Common/Constant";

interface AnswerOptionState {
  Single: {
    id: string;
    label: string;
    selected: boolean;
    timeLimit?: undefined | number | string;
  }[];
  Multiple: {
    id: string;
    label: string;
    selected: boolean;
    timeLimit?: undefined | number | string;
  }[];
  Essay: string;
  Video: string;
}

export interface TemplateBottomLeftSideProps {
  setCreatedQuestionData: any;
  textEditorQuestion: string;
  attachedQuestionImage: any;
  setAttachedQuestionImage: any;
  setTextEditorQuestion: any;
  answerOptionState: AnswerOptionState;
  answerOptionSetState: any;
  defaultAnswerOption: AnswerOptionState;
  selectedType: any;
  setSelectedType: any;
  timeLimit: string | number | undefined;
  setTimeLimit: any;
  isQuestionAnsEdited: boolean;
  setIsQuestionAnsEdited: any;
}

const TemplateBottomLeftSide: React.FunctionComponent<
  TemplateBottomLeftSideProps
> = ({
  setCreatedQuestionData,
  textEditorQuestion,
  setTextEditorQuestion,
  attachedQuestionImage,
  setAttachedQuestionImage,
  answerOptionState,
  answerOptionSetState,
  defaultAnswerOption,
  selectedType,
  setSelectedType,
  timeLimit,
  setTimeLimit,
  isQuestionAnsEdited,
  setIsQuestionAnsEdited,
}) => {
  const textEditorLimit = 1001;
  const [textareaError, setTextareaError] = useState<string | null>(null);
  const [answerError, setAnswerError] = useState<string | null>(null);
  const [countTextInEditor, setCountTextInEditor] =
    useState<number>(textEditorLimit);
  const [countManageFlag, setCountManageFlag] = useState(false);
  const companyId = localStorage.getItem("companyId") ?? "";
  const { t } = useTranslation();
  const { state } = useContext(AssessmentJsonContext) ?? {
    state: { renderJson: {} },
    dispatch: () => {},
  };

  // check text area valid
  const isTextareaValid = () => {
    return (
      textEditorQuestion.trim() !== "" &&
      textEditorQuestion.trim() !== "<p><br></p>"
    );
  };

  // handle add questions and answers
  const handleAdd = (data: any) => {
    if (isTextareaValid()) {
      setTextareaError(null);
    } else {
      setTextareaError(t("REQUIRE.QUESTION_FIELD"));
    }
    if (isAnswerSetValid(data)) {
      setAnswerError(null);
    } else {
      setAnswerError(t("REQUIRE.ANSWER_FIELD"));
    }
    if (
      isTextareaValid() &&
      isAnswerSetValid(data, true) &&
      calculateMinusAmount(countTextInEditor, textEditorLimit) >= 0
    ) {
      setCreatedQuestionData((prevData: any) => {
        const existingIndex = prevData.findIndex(
          (item: any) => item.id === selectedType.id,
        );
        if (existingIndex !== -1) {
          // If an object with the same id exists, update it
          const updatedData = [...prevData];
          updatedData[existingIndex] = {
            id: selectedType.id,
            question: textEditorQuestion,
            timeLimit: Number(timeLimit),
            image: attachedQuestionImage,
            ...data,
          };
          setSessionStorageItem("createQuestionData", updatedData);
          return updatedData;
        } else {
          // If an object with the same id doesn't exist, add a new object
          const updateData = [
            ...prevData,
            {
              id: Math.random(),
              question: textEditorQuestion,
              timeLimit: Number(timeLimit),
              image: attachedQuestionImage,
              ...data,
            },
          ];
          setSessionStorageItem("createQuestionData", [
            ...prevData,
            {
              id: Math.random(),
              question: textEditorQuestion,
              timeLimit: Number(timeLimit),
              image: attachedQuestionImage,
              ...data,
            },
          ]);
          return updateData;
        }
      });
      setTextEditorQuestion("");
      setSelectedType({ id: "", type: "Single" });
      answerOptionSetState(defaultAnswerOption);
      setTimeLimit(2);
      setAttachedQuestionImage();
      setCountManageFlag(false);
      setTextareaError(null);
      setAnswerError(null);
    }
  };

  const textEditorHandleChange = (
    content: any,
    _delta: any,
    _source: any,
    editor: any,
  ) => {
    // if (content.trim() !== "" && content.trim() !== "<p><br></p>") {
    //   setTextareaError(null);
    // } else {
    //   setTextareaError(t("REQUIRE.QUESTION_FIELD"));
    // }
    const currentLength = editor.getLength();
    setCountTextInEditor(currentLength);
    setCountManageFlag(true);
    setTextEditorQuestion(content);
  };

  return (
    <div className="questionsSec">
      <div className="quillSec">
        <div
          className={`${addDisableCss(
            state?.renderJson?.scope?.createQuestion?.functionBehavior,
          )} row`}
        >
          <div className="col-md-9">
            <div className="quillContent">
              <h6 className="quillHeading">
                {t("CREATE_ASSESSMENT.CREATE_QUESTION")}
              </h6>
              <TextEditor
                value={textEditorQuestion}
                onChange={textEditorHandleChange}
                placeholder={t("CREATE_ASSESSMENT.CREATE_QUESTION_PLACEHOLDER")}
              />
              <div className="textEditorMsg">
                <p className="errorMessage">
                  {countTextInEditor > textEditorLimit && (
                    <>
                      {t("GENERAL.MUST_BE_LESS_THAN")} {textEditorLimit - 1}{" "}
                      {t("GENERAL.CHARACTERS")}
                    </>
                  )}
                </p>
                {(countManageFlag ? 1 : textEditorLimit) !=
                  countTextInEditor && (
                  <p className="limitMsg">
                    {calculateMinusAmount(countTextInEditor, textEditorLimit)}{" "}
                    {t("CREATE_ASSESSMENT.TEXT_EDITOR_LIMIT")}
                  </p>
                )}
              </div>

              {textareaError && (
                <div className="errorMessage">{textareaError}</div>
              )}
            </div>
          </div>
          <div className="col-md-3">
            <div className="timeLimit">
              <TypeSelect
                className="timeSelect"
                options={minuteOptions(t("GENERAL.MINUTE"))}
                label={t("GENERAL.TIME_LIMIT")}
                value={timeLimit}
                onChange={(e: any) => setTimeLimit(e)}
                loadingText={t("GENERAL.LOADING")}
              />
              <div className="attachedImgSec">
                <input
                  className="attachedInput"
                  type="file"
                  accept="image/*"
                  onChange={async (e: any) => {
                    const ImageData = await ImageUploader(
                      e,
                      `interviews/${companyId}/question/${generateUUID()}`,
                    );
                    setAttachedQuestionImage(ImageData);
                  }}
                />
                <div className="attachedIconSec">
                  <p>{t("GENERAL.ATTACH_IMAGE")}</p>
                  <AttachmentIcon />
                </div>
              </div>
              {attachedQuestionImage?.ImageUrl && (
                <div className="attachedImageParent">
                  <button
                    className="attachCrossBtn"
                    onClick={() => setAttachedQuestionImage(null)}
                  >
                    X
                  </button>
                  <div className="questionImageAttParent">
                    <img
                      className="attachedImageQuestion"
                      src={
                        (attachedQuestionImage?.ImageUrl as any) instanceof Blob
                          ? URL.createObjectURL(
                              attachedQuestionImage?.ImageUrl as any,
                            )
                          : attachedQuestionImage?.ImageUrl
                      }
                      alt="Attachment"
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div
        className={`${addDisableCss(
          state?.renderJson?.scope?.answerQuestion?.functionBehavior,
        )} answerOptionSec`}
      >
        <h6 className="anserOtpHeading">
          {t("GENERAL.ANS_OPTIONS")} <span>({t("GENERAL.FILL_ANS")})</span>
        </h6>
        <AnswerOptions
          answerOptionState={answerOptionState}
          answerOptionSetState={answerOptionSetState}
          addBtnCallBack={handleAdd}
          selectedType={selectedType}
          setSelectedType={setSelectedType}
          answerError={answerError}
          setAnswerError={setAnswerError}
          isQuestionAnsEdited={isQuestionAnsEdited}
          setIsQuestionAnsEdited={setIsQuestionAnsEdited}
        />
      </div>
    </div>
  );
};

export default TemplateBottomLeftSide;
