import { Suspense, useEffect, useRef, useState } from "react";
import Header from "../Header/Header";
import { Outlet, useLocation } from "react-router-dom";
import Footer from "../../Footer/Footer";
import SupportReportPopup from "../../SupportReportPopup/SupportReportPopup";
const CreateAssessLayout: React.FunctionComponent = () => {
  const { pathname } = useLocation();
  const topRef = useRef<HTMLDivElement>(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [title, setTitle] = useState("");
  useEffect(() => {
    if (topRef.current) {
      topRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [pathname]);
  const handleOpenPopup = (title: any) => {
    setTitle(title);
    setIsPopupOpen(true);
  };
  return (
    <Suspense>
      <div ref={topRef}>
        <Header />
        <div className="totalQuestions">
          <div className="container-fluid">
            <Outlet />
          </div>
          <Footer onOpenPopup={(title: any) => handleOpenPopup(title)} />
        </div>
        <SupportReportPopup
          show={isPopupOpen}
          title={title}
          setIsPopupOpen={setIsPopupOpen}
        />
      </div>
    </Suspense>
  );
};

export default CreateAssessLayout;
